import { animateScroll } from "react-scroll";
import { MapStateToProps, connect, MapDispatchToProps } from "react-redux";
import { useHistory } from "react-router-dom";
import { utcToZonedTime } from "date-fns-tz";
import React, { FC, useState, useEffect, ChangeEvent } from "react";

import {
  FormData,
  FormErrors,
  StaticData,
  updateFormData,
  updateFormErrors,
  initialState,
} from "reducers/formReducer";
import { RootState } from "reducers";
import { Routes } from "common/routes";
import Button, {
  ButtonAppearance,
  ButtonFontSize,
} from "shared-components/Button";
import ContainerMobile from "shared-components/ContainerMobile";
import FormSelfIcons from "components/FormSelfIcons";
import FormSelfTemperature from "components/FormSelfTemperature";
import ScreenFull from "shared-components/ScreenFull";
import SvgPeople, { SvgPeopleExpression } from "components/SvgPeople";
import Transition from "shared-components/Transition";

enum Background {
  MORNING_AFTERNOON = "linear-gradient(180deg, #FFF8DF 0%, #FFFFFF 100%)",
  NIGHT = "linear-gradient(180deg, #CDE3FD 0%, #FFFFFF 100%)",
  WELL = "linear-gradient(180deg, #DFF8F8 0%, #FFFFFF 100%)",
  UNWELL = "linear-gradient(180deg, #FEEEEE 0%, #FFFFFF 100%)",
}

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  staticData: StaticData;
}

interface DispatchProps {
  updateFormData: typeof updateFormData;
  updateFormErrors: typeof updateFormErrors;
}

type Props = StateProps & DispatchProps;

const FormSelfPage: FC<Props> = ({
  formData,
  formErrors,
  staticData,
  updateFormData,
  updateFormErrors,
}) => {
  const zonedDate = utcToZonedTime(new Date(), "Asia/Singapore");
  const hours = zonedDate.getHours();
  const [background, setBackground] = useState<Background>(
    hours >= 7 && hours < 17 ? Background.MORNING_AFTERNOON : Background.NIGHT
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { push } = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    const frame = requestAnimationFrame(() => {
      setIsLoading(true);
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, []);

  useEffect(() => {
    switch (formData.isTemperatureAboveThreshold) {
      case true:
        setBackground(Background.UNWELL);
        animateScroll.scrollToBottom({ smooth: true, duration: 500 });
        break;
      case false:
        setBackground(Background.WELL);
        animateScroll.scrollToBottom({ smooth: true, duration: 500 });
        break;
      default:
    }
  }, [formData.isTemperatureAboveThreshold]);

  const handleTempChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newValue = value === "true";

    if (newValue === formData.isTemperatureAboveThreshold) {
      return;
    }

    const newFormErrors = Object.assign({}, initialState.formErrors);
    const newFormData = Object.assign({}, initialState.formData);
    newFormData.isTemperatureAboveThreshold = newValue;

    updateFormErrors(newFormErrors);
    updateFormData(newFormData);
  };

  const handleHistoryClick = () => {
    push(Routes.DECLARATION_HISTORY);
  };

  const handleNextClick = () => {
    push(Routes.FORM_SELF_MORE);
  };

  let expression;

  switch (formData.isTemperatureAboveThreshold) {
    case true:
      expression = SvgPeopleExpression.UNWELL;
      break;
    case false:
      expression = SvgPeopleExpression.WELL;
      break;
    case undefined:
    default:
      expression = SvgPeopleExpression.NEUTRAL;
  }

  return (
    <ScreenFull style={{ background }} data-testid="form-self-page">
      <ContainerMobile className="min-h-full flex flex-col items-center justify-center p-4 relative">
        <Button
          appearance={ButtonAppearance.TEXT_BLUE}
          size={ButtonFontSize.SMALL}
          onClick={handleHistoryClick}
          className="absolute top-0 right-0 m-4"
          style={{ fontWeight: 400 }}
        >
          View history
        </Button>
        <Transition isActive={isLoading} type="fly" duration={600}>
          <div>
            <FormSelfIcons zonedDate={zonedDate}></FormSelfIcons>
          </div>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600}>
          <h1 className="text-center text-web-bluedark">
            How are
            <br />
            you feeling?
          </h1>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={200}>
          <SvgPeople expression={expression} className="w-full mx-auto mb-4" />
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={400}>
          <div className="w-full mb-4">
            <FormSelfTemperature
              name="isTemperatureAboveThreshold"
              value={formData.isTemperatureAboveThreshold}
              optionOne={staticData.isTemperatureAboveThreshold[0]}
              optionTwo={staticData.isTemperatureAboveThreshold[1]}
              error={formErrors.isTemperatureAboveThreshold}
              onChange={handleTempChange}
            />
          </div>
        </Transition>

        <Transition
          isActive={formData.isTemperatureAboveThreshold !== undefined}
          type="fly"
          duration={600}
        >
          <div className="w-full flex items-center justify-end">
            <Button
              align="right"
              appearance={ButtonAppearance.TEXT_BLUE}
              size={ButtonFontSize.LARGE}
              onClick={handleNextClick}
              disabled={formData.isTemperatureAboveThreshold === undefined}
            >
              Next
            </Button>
          </div>
        </Transition>
      </ContainerMobile>
    </ScreenFull>
  );
};

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = (
  state
) => {
  return {
    formData: state.form.formData,
    formErrors: state.form.formErrors,
    staticData: state.form.staticData,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, unknown> = {
  updateFormData,
  updateFormErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSelfPage);
